<template>
  <div style="width:100%;background:#f5f5f5;display:flex;flex-direction:column;justify-content:center;padding:20px;">
    <div :style="'width:'+config.initialFrameWidth+'px;border: 1px solid #eee;height:60px;background:#fafafa;box-shadow: 2px -2px 4px #ddd;z-index:10000;'">
      <Dropdown style="margin: 15px;"  trigger="click">
        <Button type="text" icon="md-photos" >
            图片
            <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
            <DropdownItem><div @click="upImage"><Icon type="md-cloud-upload" /> 
            本地上传
            <input id="addImg"
                     type="file"
                     @change="getFileToPicture"
                     multiple="multiple"
                     accept="image/*"
                     style="display:none;"
                     />
            </div></DropdownItem> 
            <DropdownItem><div @click="editor.getDialog('insertimage').open();"><Icon type="md-photos" /> 从图库中选择</div></DropdownItem>
        </DropdownMenu>
    </Dropdown>

     <Button type="text" icon="md-film"  style="margin-right: 15px;" @click="editor.getDialog('insertvideo').open();">
            视频
        </Button>
   <Button type="text" icon="md-musical-notes"  style="margin-right: 15px;"  @click="editor.getDialog('music').open();">
            音频
        </Button>
   <Button type="text" icon="md-document"  style="margin-right: 15px;"  @click="editor.getDialog('attachment').open();">
            附件
        </Button>
 <Button type="text" icon="ios-paper" style="margin-right: 15px;">
          word文档转换
        </Button>

    </div>
    <script :id="id" type="text/plain"></script>
  </div>
</template>

<script>
import "../../public/ueditor/ueditor.config.js";
import "../../public/ueditor/ueditor.all.js";
import "../../public/ueditor/lang/zh-cn/zh-cn.js";
import "../../public/ueditor/jquery-2.2.3.min.js";

export default {
  name: "UEditor",
  props: {
    id: {
      type: String,
      default:"ueditor"
    },
     parentId: {
      type: String,
    },
    content: {
      type: String,
    },
  },
  data() {
    return {
      editor: null,
      config: {
        autoHeightEnabled: false,
        autoClearinitialContent: true, //是否自动清除编辑器初始内容，注意：如果focus属性设置为true,这个也为真，那么编辑器一上来就会触发导致初始化的内容看不到了
        initialFrameWidth: 750,
        initialFrameHeight: 500,
        UEDITOR_HOME_URL: "/ueditor/",
        toolbars: [[
            'undo', 'redo', '|',
            'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
            'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
             'fontsize', '|',
            'directionalityltr', 'directionalityrtl', 'indent', '|',
            'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
            'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
            'simpleupload', 'emotion', 'insertvideo', 'music', 'attachment', 'map', 'insertframe',  'pagebreak', 'template', 'background', '|',
            'horizontal',  'spechars', 'wordimage', '|',
            'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
             'searchreplace', 'drafts',
  
        // 'undo', //撤销
        // 'redo', //重做
        // 'bold', //加粗
        // 'indent', //首行缩进
        // 'snapscreen', //截图
        // 'italic', //斜体
        // 'underline', //下划线
        // 'strikethrough', //删除线
        // 'subscript', //下标
        // 'fontborder', //字符边框
        // 'superscript', //上标
        // 'formatmatch', //格式刷
        // 'blockquote', //引用
        // 'pasteplain', //纯文本粘贴模式
        // 'selectall', //全选
        // 'horizontal', //分隔线
        // 'removeformat', //清除格式
        // 'unlink', //取消链接
        // 'insertrow', //前插入行
        // 'insertcol', //前插入列
        // 'mergeright', //右合并单元格
        // 'mergedown', //下合并单元格
        // 'deleterow', //删除行
        // 'deletecol', //删除列
        // 'splittorows', //拆分成行
        // 'splittocols', //拆分成列
        // 'splittocells', //完全拆分单元格
        // 'deletecaption', //删除表格标题
        // 'inserttitle', //插入标题
        // 'mergecells', //合并多个单元格
        // 'deletetable', //删除表格
        // 'cleardoc', //清空文档
        // 'insertparagraphbeforetable', //"表格前插入行"
        // 'insertcode', //代码语言
        // 'fontfamily', //字体
        // 'fontsize', //字号
        // 'paragraph', //段落格式
        // 'simpleupload', //单图上传
         'insertimage', //多图上传
        // 'edittable', //表格属性
        // 'edittd', //单元格属性
        // 'link', //超链接
        // 'emotion', //表情
        // 'spechars', //特殊字符
        // 'searchreplace', //查询替换
        // 'map', //Baidu地图
        // 'gmap', //Google地图
        // 'insertvideo', //视频
        // 'help', //帮助
        // 'justifyleft', //居左对齐
        // 'justifyright', //居右对齐
        // 'justifycenter', //居中对齐
        // 'justifyjustify', //两端对齐
        // 'forecolor', //字体颜色
        // 'backcolor', //背景色
        // 'insertorderedlist', //有序列表
        // 'insertunorderedlist', //无序列表
        // 'fullscreen', //全屏
        // 'directionalityltr', //从左向右输入
        // 'directionalityrtl', //从右向左输入
        // 'rowspacingtop', //段前距
        // 'rowspacingbottom', //段后距
        // 'pagebreak', //分页
        // 'insertframe', //插入Iframe
        // 'imagenone', //默认
        // 'imageleft', //左浮动
        // 'imageright', //右浮动
        // 'attachment', //附件
        // 'imagecenter', //居中
        // 'wordimage', //图片转存
        // 'lineheight', //行间距
        // 'edittip ', //编辑提示
        // 'customstyle', //自定义标题
        // 'autotypeset', //自动排版
        // 'webapp', //百度应用
        // 'touppercase', //字母大写
        // 'tolowercase', //字母小写
        // 'background', //背景
        // 'template', //模板
        // 'scrawl', //涂鸦
        // 'music', //音乐
        // 'inserttable', //插入表格
        // 'drafts', // 从草稿箱加载
        // 'charts', // 图表
                ]],
      },
    };
  },
  mounted() {
    //初始化UE
    const _this = this;
    this.editor = UE.getEditor(this.id,this.config);
    this.editor.ready(function () {
      _this.setTimeOutContent();
    });
    this.editor.addListener("contentChange", function () {
      _this.$emit("ueditorContent", _this.getUEContent());
    });
  },
  destoryed() {
    this.editor.destory();
  },
  methods: {
    //弹出图片上传的对话框
    upImage(){
        this.$("#addImg").click();
       
    },
    getFileToPicture () {
      var that=this;
            var img=that.$("#addImg");
            var files = that.$(img)[0].files[0];
            
            if (files.type.indexOf("image/") != 0) {
                alert("图片格式不支持");
                return;
            }
            if (files.size > 20480000) {
                alert("图片超出2M限制");
               img.val('');
                return;
            }
            window.UE.uploadFile(files,function(data){
                          var link = data.get('host') + '/' + data.get('key');
                          that.editor.execCommand('inserthtml', '<img src="'+link+'"/>');
                          //保存资原库
                          var p={
                            "custGlobalId":parent.vue.custGlobalId,
                            "fileType":"image",
                            "groupId":10000,
                            "fileName":files.name,
                            "fileSize":files.size,
                            "orgCode":parent.vue.loginInfo.userinfo.orgCode,
                            "filePath":link
                            };
                        //    console.log(p);
                          that.$post("/old/api/pc/fileUpload/saveFileUpload", p).then((res) => {
                            //  console.log(res);
                            });
                        },function(data){
                          img.val('');
                       });
        },
    setTimeOutContent() {
      setTimeout(() => {
        if (this.content == "") {
          this.setTimeOutContent();
        } else {
          this.editor.setContent(this.content); // 确保UE加载完成后，放入内容。
        }
      }, 100);
    },
    getUEContent: function () {
      return this.editor.getContent();
    },
    getContentTxt: function () {
      return this.editor.getContentTxt();
    },
  },
};
</script>
<style lang="less" scoped>
</style>
