<template>
  <LiefengModal
      title="预览文本"
      :fullscreen="fullscreen"
      :value="value"
      :toggle="toggle"
  >
    <template v-slot:contentarea>
      <div>
          <Divider orientation="left">请扫码预览文本内容效果</Divider>
      </div>
      <div class="container">
          <img :src="textObj.signInUrl" alt=""
               style="display: block;
                  margin: 0 auto;
                  width: 200px;
                  height: 200px;">
      </div>
    </template>
    <template v-slot:toolsbar>
      <Button type="primary" @click="toggleFn2">关闭</Button>
    </template>
  </LiefengModal>
</template>

<script lang="js">
import LiefengModal from "@/components/LiefengModal";

export default {
  name: "labelToggle",
  props: {
    value: Boolean,
    fullscreen: Boolean,
    toggleFn1: Function,
    toggleFn2: Function,
    toggle: {
      type: Boolean
    },
    textObj:{
      type:Object
    }
  },
  data() {
    return {
      bool: '', //全选和取消全选
      list: [], //保存数组
      listCode: [], //编码
      labelList: [],
      indeterminate: false,
      checkAll: false,
      labelId: '',  //单个信息的id
      labelObj: {}, //字典
    }
  },
  methods: {
    // imgFn(){
    //     var image = new Image();
    //   // 解决跨域 Canvas 污染问题
    //   image.setAttribute("crossOrigin", "anonymous");
    //   image.onload = function() {
    //     var canvas = document.createElement("canvas");
    //     canvas.width = image.width;
    //     canvas.height = image.height;
    //     var context = canvas.getContext("2d");
    //     context.drawImage(image, 0, 0, image.width, image.height);
    //     var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
    //     var a = document.createElement("a"); // 生成一个a元素
    //     var event = new MouseEvent("click"); // 创建一个单击事件
    //     a.download = "预览文本内容二维码";
    //     a.href = url; // 将生成的URL设置为a.href属性
    //     a.dispatchEvent(event); // 触发a的单击事件
    //   };
    //   image.src = this.textObj.signInUrl;
    // }
  },
  computed: {},
  components: {
    LiefengModal
  }
}
</script>

<style scoped lang="less">
#determine {
  margin-right: 10px;
}

.title {
  margin-left: 20px;
}

.main {
  margin: 20px 0;
  display: flex;
  justify-content: center;

  .group {
    display: flex;
    width: 100%;
    margin: 0 20px;
    flex-wrap: wrap;

    > brack {
      width: 33.333%;
      margin-bottom: 10px;
    }
  }
}

.header {
  display: flex;
  margin: 20px;

  > Button:first-child {
    margin-right: 20px;
  }
}
</style>
