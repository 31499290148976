<template>
    <div class="container-div">
        <div class="center-table">
            <!-- <div style="margin-bottom: 10px; display: flex">
                <Input v-model="groupName" @on-search="search" search enter-button="查询" style="width: 560px; margin-right: 10px" placeholder="群名称"></Input>
                <Button type="success" icon="ios-refresh" @click="reset">重置</Button>
            </div> -->
            <div class="table">
                <LiefengTable
                    :tableData="tableData"
                    :talbeColumns="talbeColumns"
                    :loading="loading"
                    :height="tableHeight"
                    :fixTable="true"
                    @tableSelect="tableSelect"
                    :hidePage="true"
                    ref="lftable"
                ></LiefengTable>
            </div>
        </div>
        <div class="right-table">
            <div class="right-center">
                <Button type="primary" style="margin-bottom: 14px" @click="pushToRightList">右移</Button>
                <Button type="error" style="margin-bottom: 14px" @click="delRightList">删除</Button>
                <Button type="warning" @click="clearRightList">清空</Button>
            </div>

            <div class="right-right">
                <div style="text-align: center; font-size: 14px; font-weight: bold; line-height: 40px; color: #515a6e; background: #f8f8f9">已选择</div>
                <List border size="small">
                    <ListItem v-for="(item, index) in rightList" :key="item.id">
                        <div style="width: 100%; display: block;cursor: pointer;" @click="selectRightList($event, index)">
                            {{ item.groupName }}
                        </div>
                    </ListItem>
                </List>
            </div>
        </div>
    </div>
</template>

<script>
import LiefengTable from "@/components/LiefengTable"
// import { set } from 'vue/types/umd'
export default {
    components:{LiefengTable},
    props:['buttonRoot'],
    data(){
        return {
            tableHeight:'',
            groupTypeList:[],
            tableData:[],
            talbeColumns:[
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title:'群名称',
                    align: "center",
                    key:'groupName',
                    // width: 200,
                },
                {
                    title:'群类型',
                    align: "center",
                    key:'groupTypeName',
                    // width: 200,
                },
                {
                    title:'群成员数（人）',
                    align: "center",
                    key:'userNum',
                    // width: 200,
                }
            ],
            loading:false,
            pageSize:20,
            total:0,
            page:1,

            rightList:[],
            selectList:[],
            removeRightList:[],
        }
    },
    methods:{
        pushToRightList(){
            if(this.selectList.length == 0){
                return this.$Message.warning({
                    content:'请勾选数据',
                    background:true
                })
            }
            console.log(this.selectList)
            let data = []
            this.selectList.map((el, index) => {
                if (!this.rightList.find(item => item.groupId == el.groupId)) {
                    data.push(this.selectList[index]);
                }
            })
            console.log(data)
            this.rightList.push(...data)
            console.log(this.rightList)
            this.$refs.lftable.$refs.lftable.selectAll(false);
        },
        // 选中右侧
        selectRightList(e, index) {
            console.log(e)
            console.log(index)
            if (e.target.parentNode.classList.contains("active")) {
                e.target.parentNode.classList.remove("active");
                this.removeRightList.splice(index, 1);
            } else {
                e.target.parentNode.classList.add("active");
                this.removeRightList.push(this.rightList[index]);
            }
        },
        delRightList(){
            if (this.removeRightList.length == 0) {
                this.$Message.error({
                    background: true,
                    content: "请从已选择列表中选中需要删除群",
                });
                return;
            }

            console.log(this.removeRightList)
            console.log(this.rightList)
            let data = []
            this.rightList.map((el, index) => {
                if (!this.removeRightList.find(item => item.groupId == el.groupId)) {
                    data.push(this.rightList[index]);
                }
            })
            this.rightList = []
            setTimeout( () => {
                this.rightList = data
            },10)
            this.removeRightList = [];

            console.log(this.removeRightList)
            console.log(this.rightList)
        },
        clearRightList(){
            this.rightList = []
            console.log()
        },
        tableSelect(val){
            this.selectList = val
        },
        search(){
            this.page = 1
            this.pageSize = 20
            this.getList()
        },
        reset(){
            this.groupName = ''
            this.page = 1
            this.pageSize = 20
            this.getList()
        },
        getArrDifference(arr1, arr2) {
            return arr1.concat(arr2).filter(function (v, i, arr) {
                return arr.indexOf(v) === arr.lastIndexOf(v);
            });
        },
        hadlePageSize(val){
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        async getList(){
            this.loading = true
            await this.$get('/sendmsg/app/chat/group/selectChatGroupType',{
                // groupName:this.groupName,
                // custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
                // orgCode:'440103006106'
                // oemCode: parent.vue.oemInfo.oemCode,
                // pageSize:this.pageSize,
                // page:this.page
            }).then(res => {
                this.loading = false
                if(res.code == 200){
                    for(let key in res.data){
                        res.data[key].map(item => {
                            this.tableData.push(item)
                        })
                    }
                    
                    // console.log(this.groupTypeList)
                    // res.dataList.map( item => {
                    //     this.groupTypeList.map(e =>{
                    //         if(item.groupType == e.dictKey){
                    //             item.groupTypeName = e.dictValue
                    //         }
                    //     })
                    // })
                    // // this.tableData = res.dataList
                    // this.total = res.maxCount
                    // this.page = res.currentPage
                }else{
                    this.$Message.error({
                        content:"获取群列表失败",
                        background:true
                    })
                }
            })
        },
        // async getSelect() {
        //     await this.$get("/old/api/common/sys/findDictByDictType", {
        //         dictType: "CHAT_GROUP_TYPE"
        //     }).then(res => {
        //         this.groupTypeList = res.dataList;
        //     });
        // },
    },
    async created(){
        // this.tableHeight = String(window.innerHeight - 210);
        // await this.getSelect()
        if(this.buttonRoot == '1001')
        await this.getList()
    },
}
</script>

<style lang="less" scoped>
.container-div{
  height: 100%;
  display: flex;
  width: 100%;
  .tree-div{
    width: 200px;
    height: 100%;
    /deep/.ivu-tree {
      height: calc(100vh - 155px);
      overflow: auto;
    }
  }
  .center-table{
    width: calc(100% - 430px);
    margin-left: 20px;
    // border: 1px solid red;
  }
  .right-table{
    margin-left: auto;
    width:410px;
    height: calc(100vh - 127px);
    display: flex;
    .right-center{
      width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .right-right{
      width: 300px;
      border: 1px solid #dcdee2; 
      overflow: auto;
    }
  }
  /deep/.active {
      background: #19be6b;
      color: #fff;
    }
}
</style>