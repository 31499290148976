<template>
<div ref="lfTb">
        <Table ref="lftable" :highlight-row="highlightRow" @on-current-change="currentChangeFn"  :border="tableData && tableData.length" stripe :height="tableHeight" :columns="columnList" :loading="loading" :data="tableData" @on-selection-change="tableSelete" @on-sort-change="tableSort" :show-summary="showsummary" :summary-method="summaryData!=null&&summaryData!={}?handleSummary:null" :span-method="handleSpan" :class="backgroundText && backgroundText != '' ? 'table-div' :''" :no-data-text="noText">
        </Table>
        <div v-if="backgroundText && backgroundText != ''" class="watermark-div" :style="`height:${tableHeight}px;width:calc(100% - 40px);background: url('${backgroundText}');opacity: 0.06;`">
            
        </div>
        <Page v-if="!hidePage" style="float:right;margin:10px;" @on-change="currentPage" :current="curPage" @on-page-size-change="hadlePageSize" :page-size="pageSize" :total="total" :page-size-opts="pagesizeOpts" size="small" show-total show-elevator show-sizer />

        
</div>
</template>

<script>

export default {
    name: "liefengtable",
    props: {
        tableData: Array, //传入的数据集
        talbeColumns: {
            type:Array,
            default:() =>{
                return []
            }
        },//传入的表头集
        loading:Boolean,//传入的加载等待开关
        height:String,//表格高度
        fixTable:Boolean,//满屏固定表格，自定义height将无效

        pageSize:Number,//自定义每页显示数量
        pagesizeOpts:Array,//每页条数切换的配置
        total: Number,//总数
        curPage: Number,//当前页
        hidePage:Boolean,//是否隐藏分页

        highlightRow:{type:Boolean,default:false},//选中单行

        showsummary:{type:Boolean,default:false},//显示合计行
        summaryData:{type:Object,default:() => {return {}} },//指定的合计数据
        currentChange:{type:Object,default:() => {return {}} },//选中单行触发

        // 合并行列方法，父组件必须绑定handleSpanFather
        handleSpanFather:{
            type:Function,
            default:null
        },
        // 加水印需要传的图片地址或者base64编码
        backgroundText:{
            type:String,
            default:''
        },
        // 是否显示序号列，false为不显示
        showIndex:{
            type:Boolean,
            default:()=>{
                return true
            }
        },
        noText:{
            type:String,
            default:'暂无数据'
        },
    },
    components: {
    },
    data() {
        return {
            tableHeight:'',
            selection: [],
            delData: {},
            pSize:0,
            columnList:[]
        };
    },
    created() {
        //初始化值
        if(this.showsummary){
            this.tableHeight=this.height-50;
        }else{
            this.tableHeight=this.height;
        }

        this.pSize=this.pageSize==undefined||this.pageSize<1?10:this.pageSize;
    },
    mounted() {
        var that=this;
        //  this.tableHeight=this.height;
        if(this.fixTable){//自动满屏
            this.$(window).resize(this.resize);
            this.resize();
        }
    },
    methods: {
        resize(){
            this.tableHeight = window.innerHeight - (this.$refs.lftable?this.$refs.lftable.$el.offsetTop:10) - 120 ;
            if(this.showsummary){
            this.tableHeight=this.tableHeight-50;
            }
        },
        hadlePageSize(val) {//改变每页条数
            this.pSize=val;
            if (this.curPage === 1) {
                this.$emit('hadlePageSize', {
                page: 1,
                pageSize: val
                })
            }

        },
        currentPage(val) {
            this.$emit('hadlePageSize', {
                page: val,
                pageSize: this.pSize
            })
        },

        tableSort(sortdata){
          this.$emit("tableSort", sortdata);
        },
        tableSelete(selection) {
            this.selection = selection;
            this.$emit("tableSelect", selection);
        },
        handleSummary({ columns, data }){
           return this.summaryData;
        },
        currentChangeFn(newRow,oldRow) {
            this.$emit("currentChange", newRow,oldRow);
        },
        handleSpan(val){
            if(this.handleSpanFather){
               return this.handleSpanFather(val)
            }
        },
    },
    watch:{
        talbeColumns:{
            handler(newVal,oldVal){
                if(newVal && newVal.length && this.showIndex){
                    let list = []
                    newVal.map(item =>{
                        list.push(item.type)
                    })
                    let listof = list.join(',')
                    if(listof.indexOf('index') > -1){
                        this.columnList = newVal
                    }else{
                        this.columnList = [
                            {
                                title: "序号",
                                type: "index",
                                width: 80,
                                align: "center",
                            },
                            ...newVal
                        ]
                    }

                    var indexs = 0
                    var items = {}
                    this.columnList.map((item,index) =>{
                        if(item.type == 'selection'){
                            indexs = index
                            items = item
                        }
                    })
                    if(indexs > 0){
                        this.columnList.splice(indexs,1)
                        this.columnList = [{...items,'width':'60','align': "center",},...this.columnList]
                    }   


                    //  this.columnList = this.quchong(this.columnList);
                }else{
                    this.columnList = newVal
                }
                
            },
            immediate:true
        }
    }
};
</script>

<style lang="less" scoped>
/deep/ .ivu-page-item-jump-next:after,
    .ivu-page-item-jump-prev:after {
        content: "\2022\2022\2022" !important;
}
::v-deep.ivu-select-dropdown{
    &::-webkit-scrollbar{
        height: 15px !important;
    }
     &::-webkit-scrollbar-thumb{
        background: #2d8cf0 !important;
        color: #2d8cf0 !important;
    }
} 
/deep/.ivu-table-tip{
    &::-webkit-scrollbar{
        height: 15px !important;
    }
    &::-webkit-scrollbar-thumb{
        background: #2d8cf0 !important;
        color: #2d8cf0 !important;
    }
}
/deep/.ivu-table-overflowX{
        overflow-x: scroll !important;
    &::-webkit-scrollbar{
        height: 15px !important;
    }
    &::-webkit-scrollbar-thumb{
        background: #2d8cf0 !important;
        color: #2d8cf0 !important;
    }
}
/deep/.ivu-table-overflowY{
    overflow-x: hidden;
    &::-webkit-scrollbar{
        width: 15px !important;
    }
    &::-webkit-scrollbar-thumb{
        background: #2d8cf0 !important;
        color: #2d8cf0 !important;
    }
}
/deep/.ivu-table-header {
    overflow: hidden;
    margin-right: 7px !important;
}
// /deep/.ivu-table-fixed-right{
//     right: 15px !important;
// }
.table-div{
    z-index:1;
    /deep/.ivu-table{
         background-color: transparent !important;
        td{
             background-color: transparent !important;
        }
       
    }
    /deep/.ivu-table-tbody{
        td{
             background-color: transparent !important;
        }
    }
    /deep/ .ivu-table-fixed-body{
        td{
            background: #fff !important;
        }
    }
}

.watermark-div{
    z-index: 0;
    position: absolute;
    background:#fff;
    left:20px;
    top: 0;
    color: rgba(black, 0.2);
}

</style>
